#portfolio {
  padding-top: 6rem;
}

.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.portfolio__content__wrapper {
}

.portfolio__item {
  background: var(--color-bg-variant);
  padding: 1.3rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.portfolio__item:hover {
  border-color: var(--color-bg-variant);
  background: transparent;
}

.portfolio__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio__item h3 {
  margin: 1.2rem 0 0.5rem;
}

.portfolio__item p {
  margin: 0 0 5rem;
  color: var(--color-light);
}

.portfolio__item-cta {
  display: flex;
  gap: 1rem;
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) ============ */

@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }

  #portfolio {
    padding-top: 3rem;
  }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) ============ */

@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  #portfolio {
    padding-top: 3rem;
  }
}
